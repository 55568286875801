import BillOfDebt from '@components/loan-application-components/accountCreditBillOfDebt';
import SEO from '@components/seo';
import GatewayAPI from '@lib/gateway/GatewayAPI';
import '@src/sentry'; // Enable Sentry monitoring for errors
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Contact from '../../components/contact';
import Hero from '../../components/hero';
import Layout from '../../components/layout';
import LanaContainer from '../lana/lanaContainer';

export default function BillOfDebtPage({ data }) {
  if (typeof window !== 'undefined' && !GatewayAPI.isAuthenticated()) {
    navigate('/fortsatt');
    return null;
  }
  const pageContent =
    data.contentfulPage.pageContent[0].bodyText.childMarkdownRemark.html;
  const headLine = data.contentfulPage.headLine;
  const headerTitle = data.contentfulPage.pageHeader;

  return (
    <Layout newStyle>
      <SEO title={headLine} noIndex />
      <Hero title={headerTitle} />

      <LanaContainer>
        <BillOfDebt
          description={pageContent}
          pathPrefix="/kredit"
          isAccountCreditIncreaseLimit={false}
          campaign="ZeroInterestTwoMonths"
        />
      </LanaContainer>
      <Contact />
    </Layout>
  );
}

BillOfDebtPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query AccountCreditBillOfDebtPageQuery {
    contentfulPage(pageUrl: { eq: "/kredit/kreditavtal" }) {
      headLine
      pageHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
